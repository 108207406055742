.watch-img { 
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;

}

.watch-img-body { 
    display: grid;
    grid-template-areas: "img buttons";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.watch-img-container {
    width: 100%;
    height: 100%;
    border: 1px solid white;
}
.watch-img-preview-box {
    width: 512px;
    height: 512px;
}

.icon-down-body {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    color: #ebebeb;
    
}

.icon-down-button {
    width: 100%;
    min-width: 18rem;
    height: 100px;
    border: 1px solid gray;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25pt;
}

.icon-down-button:hover{
    border: 5px solid #ebebeb
}

.img-upload { 
    position: absolute;
    width: 50%;

    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.img-upload  input[type="file"]{ 
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    clear: rect(0,0,0,0);
    border: 0;
}

.img-upload span {
    width: 80%;
    color: #ebebeb;
    overflow: hidden;
}

.img-upload label {
    width: 15%;
    border: 1px solid #ebebeb;
    border-radius: .25rem;
    text-align: center;
}

.icon-gen { 
    position: absolute;
}

.select-img {
    min-width: 5rem;
}

.select-img:hover {
    border: 2px solid #ebebeb
}

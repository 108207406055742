.control-group > *{
    @apply mt-2;
}

.settings-group > * {
    @apply mb-2;
}

.products-tab, .product-content {
    transition: width 0.2s ease-in-out;
}

ul > li {
    @apply pt-4 pb-4 pl-8 flex justify-start text-white hover:bg-[#262E43];
    
}

.maxHeight{
    height: 100%;
}

.menu-icon-border {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid gray;
    opacity: 0.3;
}

.scroll-none ::-webkit-scrollbar{
    display: none;
}

.google-ads {
    position: fixed;
    bottom: 0;
    background-color: black;
    width: 100%;
    height: 5rem;
}

.floating-menu {
    display: flex;
    justify-content: center;
    transition: width 0.5s, border-radius 1s;
    border-radius: 50%;
    width: 81px;
    height: 81px;
    position: fixed;
    right: 0;
    margin: 3rem 3rem;
    bottom: 0;
    /* background: linear-gradient(to right, #1e40af, #002b71); */
    cursor: pointer;
}

.floating-menu-open  {
    transition: width .5s, border-radius 1s;
    border-radius: 40px;
    position: fixed;
    width: 400px;
    right: 0;
    margin: 3rem 3rem;
    bottom: 0;
    background: linear-gradient(to right, #1e40af, #002b71);
    padding: 1rem 1.5rem 1rem 1.5rem;
    align-items: center;
}

.floating-menu-icon {
    width: 35px ;
    height: 54px ;
    margin: auto;
}

@media (max-width: 600px){
    .floating-menu{
        width: 53px;
        height: 53px;
        margin-bottom: 1.5rem ;
        margin-right: 1.5rem;
    }
    .floating-menu-icon{
        width: 26px;
        height: 37px;
    }
    .floating-menu-open{
        height: 53px;
        width: 300px !important;
        margin:  1.5rem !important;
        bottom: 0 !important;
        display: flex;
        align-items: center;
        padding: 0 !important;
    }
    .floating-menu-inside > img {
        width: 21px !important;
        height: 100%;
    }
    .floating-menu-inside {
        width: 21px;
        height: 26px;
        margin: auto;
    }
}



.floating-menu-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.floating-menu-inside > img {
    width: 31px;
    height: 100%;
}

.floating-menu-inside > img:hover {
    cursor: pointer;
    transform: scale(1);
    transition: .3s ease-in-out;
}
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

td {
    @apply text-center p-4;
}

.mainClass {
    transition: margin 0.2s ease-in-out;
}

.store-img { 
    width: 30px;
    height: 30px;
    display: block;
    margin: auto;
}